import { render, staticRenderFns } from "./PlusMoreList.vue?vue&type=template&id=d37f3cfe&scoped=true&"
import script from "./PlusMoreList.vue?vue&type=script&lang=ts&"
export * from "./PlusMoreList.vue?vue&type=script&lang=ts&"
import style0 from "./PlusMoreList.vue?vue&type=style&index=0&id=d37f3cfe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d37f3cfe",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VMenu})
