import { SetupContext, ref } from '@vue/composition-api';
import { getUsers } from '@ax/api-clients-accounts/clients/users.client';
import { AccountUserWithZones } from '@ax/api-clients-accounts/models';
import { DataTableState, DataTablePageState } from '@/utils/data-table-state';
import { DEFAULT_TABLE_PAGINATION } from '@/utils/constants';
import { QueryStateParametersConfiguration } from '@/utils/query-state';
import { QueryStateStorageConfig } from '@/utils/storage';
import { useRouter } from '@ax/vue-utils/router';

export function getUserQueryStateConfiguration(): QueryStateParametersConfiguration<{}> {
  return {
    limit: { defaultValue: DEFAULT_TABLE_PAGINATION, storeInBrowser: true },
    page: { defaultValue: 1 },
  };
}

export function useUsersTable(
  context: SetupContext,
  queryStateParametersConfiguration?: QueryStateParametersConfiguration<
    DataTablePageState<AccountUserWithZones>
  >,
) {
  const router = useRouter();
  const users = ref<AccountUserWithZones[]>([]);
  const totalServerUsers = ref<number>();
  const queryStateConfig =
    queryStateParametersConfiguration || getUserQueryStateConfiguration();
  const storageConfig: QueryStateStorageConfig = {
    key: 'ax-users-prefs',
    store: localStorage,
  };

  function getUsersList(query: string): Promise<void> {
    const queryParams = new URLSearchParams(query);
    const limitString = queryParams.get('limit');
    const pageString = queryParams.get('page');
    const limit = limitString ? parseInt(limitString, 10) : undefined;
    const page = pageString ? parseInt(pageString, 10) : undefined;
    return getUsers({ limit, page })
      .then(({ data, metadata }) => {
        users.value = data;
        totalServerUsers.value = metadata.total_count;
      })
      .catch(() => {
        totalServerUsers.value = 0;
      });
  }

  const { tableState, queryState, updateQueryState } =
    DataTableState.synchronize<
      AccountUserWithZones,
      DataTablePageState<AccountUserWithZones>
    >(router.currentRoute, router, queryStateConfig, {
      callback: ({ apiQuery }) => getUsersList(apiQuery),
      storageConfig,
    });

  return {
    users,
    totalServerUsers,
    tableState,
    queryState,
    updateQueryState,
    storageConfig,
  };
}
