var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ax-users-table"},[_c('ax-table',{attrs:{"footer-props":_vm.usersFooter,"headers":_vm.usersHeaders,"items":_vm.users,"column-select":false,"options":_vm.tableStateLocal,"server-items-length":_vm.serverUsersLength,"item-key":"id"},on:{"update:options":function($event){_vm.tableStateLocal=$event}},scopedSlots:_vm._u([{key:"item.email",fn:function(ref){
var item = ref.item;
return [(!_vm.matchesCurrentUser(item))?_c('router-link',{staticClass:"tw-inline-block tw-leading-tight",attrs:{"to":_vm.userEditorRoute(item.id)}},[_vm._v(" "+_vm._s(item.email)+" ")]):[_vm._v(_vm._s(item.email))]]}},{key:"item.first_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.first_name)+" ")]}},{key:"item.last_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.last_name)+" ")]}},{key:"item.zone_assignments",fn:function(ref){
var item = ref.item;
return [(item.account_rbac_role === _vm.globalAdminRole)?[_vm._v(" "+_vm._s(_vm.$t('setup.users.allZones:phrase'))+" ")]:[_c('ax-plus-more-list',{attrs:{"items":item.zone_assignments,"max-visible":5,"separator":", "},scopedSlots:_vm._u([{key:"default",fn:function(slotData){return [_vm._v(_vm._s(slotData.item.zone.name))]}}],null,true)})]]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("setup.users.userStatuses:values." + (item.status))))+" ")]}},{key:"item.global_administrator",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.account_rbac_role === _vm.globalAdminRole ? _vm.$t('general.phrases.yes') : _vm.$t('general.phrases.no'))+" ")]}},{key:"item.two_factor_authentication",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.two_factor_authentication ? _vm.$t( ("setup.users.tfaTypes:values." + (item.two_factor_authentication)) ) : _vm.$t('setup.users.tfaTypes:values.disabled'))+" ")]}},{key:"item.tfa_enabled",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.$t(("general.statuses." + (item.tfa_enabled ? 'enabled' : 'disabled')))))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!_vm.matchesCurrentUser(item))?_c('ax-table-row-actions-column',[_c('v-list-item',{attrs:{"to":_vm.userEditorRoute(item.id)}},[_vm._v(" "+_vm._s(_vm.$t('setup.users.editUser:phrase'))+" ")]),(_vm.auth0Milestone1Flag && item.tfa_enabled)?_c('v-list-item',{on:{"click":function($event){return _vm.modals.resetMFA.open({ user: item })}}},[_vm._v(" "+_vm._s(_vm.$t('setup.users.resetMFA:phrase'))+" ")]):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.modals.removeUser.open({ user: item })}}},[_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.$t('setup.users.removeFromAccount:button')))])])],1):_vm._e()]}},_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}})],null,true)}),_c('ax-modal',{model:{value:(_vm.modals.resetMFA.opened),callback:function ($$v) {_vm.$set(_vm.modals.resetMFA, "opened", $$v)},expression:"modals.resetMFA.opened"}},[_c('ax-reset-authenticators-modal',_vm._b({on:{"close-modal":_vm.modals.resetMFA.close,"reset":_vm.reloadTableData}},'ax-reset-authenticators-modal',_vm.modals.resetMFA.props,false))],1),_c('ax-modal',{model:{value:(_vm.modals.removeUser.opened),callback:function ($$v) {_vm.$set(_vm.modals.removeUser, "opened", $$v)},expression:"modals.removeUser.opened"}},[_c('ax-remove-user-modal',_vm._b({on:{"close-modal":_vm.modals.removeUser.close,"removed":_vm.reloadTableData}},'ax-remove-user-modal',_vm.modals.removeUser.props,false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }